import React, { memo,useState,useEffect,useContext } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { compose } from "redux";
import { makeSelectCardCasesPage } from "../AllottedCases/selectors";
import { useInjectReducer } from "../../utils/injectReducer";
import { ApiDispatchContext } from "../../context/ApiContext";
import makeSelectAllottedCasesDetailsPage from "./selectors";
import reducer from "./reducer";
import path from "../../constants/pathConstants";
import CommonNavbar from "../../components/CommonNavbar";
import CaseCard from "./CaseCard";
import ErrorAlert from "../../components/ErrorAlert";
import { getUrlParameter } from "../../constants/commonFunction";
import { cardDataVendor } from "../../constants/allocatedCasesTabConstants";
import CardTabs from "../../components/CardTabs";
export function AllottedCasesDetailsPage({ allCases }) {
  useInjectReducer({ key: "allottedCasesDetailsPage", reducer });
  const [selectedValue, setSelectedValue] = useState();
  const api = useContext(ApiDispatchContext);
  const [modal, setModal] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [modalBody, setModalBody] = useState("");
  const toggle = () => setModal(!modal);

  useEffect(() => {
    window.scrollTo(0, 0);
      let param = {
        "caseType": "alloted",
      }
      api.getCasesDetails(param).then(data => {
        if (data.data && data.data.success) {
          selectedCard(data.data.data.rows)
        } else {
          setModalTitle("Error");
          if(data.data.error && data.data.message){
            setModalBody(data.data.message)
          }
          else{
            setModalBody('Please try after sometime');
          }
          toggle();
        }
      }).catch(err => {
        setModalTitle("Error");
        if(err && err.response && err.response.data && err.response.data.message){
          setModalBody(err.response.data.message);
        } else {
          setModalBody('Please try after sometime');
        }
        toggle();
      })
  }, []);

  const selectedCard = (cards) => {
    cards.forEach(element => {
      if (element[1].value === Number(getUrlParameter('case'))) { // it is expected that on 0th index case details will be placed
        setSelectedValue([element]);
      }
    });
  }
  return (
    <div className="allotted-cases-details-page">
      <CommonNavbar title="Allotted Cases" backButton={path.ALLOTTED_CASES} search={false} download={false} navbarname='AssignedBlocksAllottedCases'/>
      <div style={{ marginTop: "20px" }}></div>
      <CaseCard selectCards={selectedValue} />
      <ErrorAlert
        modal={modal}
        toggle={toggle}
        modalTitle={modalTitle}
        modalBody={modalBody}
      />
      <CardTabs tabData={cardDataVendor} />
    </div>
  );
}

AllottedCasesDetailsPage.propTypes = {
  dispatch: PropTypes.func
};

const mapStateToProps = createStructuredSelector({
  allottedCasesDetailsPage: makeSelectAllottedCasesDetailsPage(),
  allCases: makeSelectCardCasesPage()
});

export function mapDispatchToProps(dispatch) {
  return {
  };
}

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps
);

export default compose( withConnect, memo )(AllottedCasesDetailsPage);
